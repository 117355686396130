import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'

import Background from '../components/Background'
import Social from '../components/Social'
import Button from '../components/Button'

import { device, colors } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper }
  ${ props => props.theme.backgroundTop }
  padding-right: 0;

  @media ${ device.sm } {
    ${ props => props.theme.paddingTop }
  }
`

const Header = styled.h1`
  margin-top: 6.25rem;

  @media ${ device.sm } {
    margin-top: 0;
  }
`

const Image = styled(GatsbyImage)`
  @media ${ device.sm } {
    margin-top: 3.125rem;
  }
`

const BoatButton = styled(Button)`
  position: relative;
  top: -2px;
`

const BootsverleihPage = ({ data: { datoCmsBoat: { header, text, image } } }) => (
  <Background openTop>
    <Container>
      <Row>
        <Col xs>
          <Header>{header}</Header>
          <div dangerouslySetInnerHTML={{ __html: text }}/>
        </Col>
        <Col xs={12} smOffset={1} sm={7}>
          <Image fluid={image.fluid}/>
          <a href="https://www.kanuliebe.com/">
            <BoatButton light>
              Zur Kanuliebe
            </BoatButton>
          </a>
        </Col>
      </Row>
    </Container>
    <Social/>
  </Background>
)

export const query = graphql`
  query BootsverleihPage {
    datoCmsBoat {
      header
      text
      image {
        fluid(maxWidth: 500, imgixParams: {
          fm: "jpg",
          auto: "compress,format"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default BootsverleihPage
